<template>
  <div class="wrapper">
    <el-form :model="staffForm" ref="staffForm" disabled="disabled" size="mini">
      <el-row type="flex" class="row-bg" justify="end">
        <el-col :md="4">
          <el-form-item label="录入人">
            <el-input v-model="staffForm.staffName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <el-form-item label="部门">
            <el-input v-model="staffForm.deptName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :md="4">
          <el-form-item label="小组">
            <el-input v-model="staffForm.deptTeamName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'inputUser',
  props: {
    isCookie: Boolean,
    stffForm: Object
  },
  data() {
    return {
      staffForm: {
        staffName: '777',
        deptName: '',
        deptTeamName: ''
      },
      userInfo: {},
      disabled: true
    };
  },
  created() {
    this.initData();
  },
  mounted() {},
  watch: {},
  methods: {
    initData() {
      this.getStaffInput();
    },
    getStaffInput() {
      if (this.isCookie === true) {
        this.userInfo = this.$cookies.get('userInfo');
        this.staffForm.staffName = this.userInfo.stff_name;
        this.staffForm.deptName = this.userInfo.dept_name;
        this.staffForm.deptTeamName = this.userInfo.dept_team_name;
      } else {
        setTimeout(() => {
          this.staffForm.staffName = this.stffForm.stff_name;
          this.staffForm.deptName = this.stffForm.dept_name;
          this.staffForm.deptTeamName = this.stffForm.dept_team_name;
        }, 200);
      }
    },
    getData() {
      const staffInfoIds = {
        user_id: this.userInfo.user_id,
        stff_id: this.userInfo.stff_id,
        stff_name: this.userInfo.stff_name,
        dept_id: this.userInfo.dept_id,
        dept_team_id: this.userInfo.dept_team_id
      };
      return staffInfoIds;
    }
  }
};
</script>

<style scoped lang="scss">
.wrapper ::v-deep .el-input {
  width: 125px;
}
.wrapper ::v-deep .el-form-item__label {
  width: 78px;
  color: $color-info;
}
</style>
